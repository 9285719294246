import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {deleteStringAsync, getStringAsync, setStringAsync} from '../storage';
import {useRouter, useSegments} from 'expo-router';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

const TokenContext = createContext(null);

// This hook will protect the route access based on user authentication.
function useProtectedRoute(token) {
	const segments = useSegments();
	const router = useRouter();

	useEffect(() => {
		const inAuthGroup = segments[0] === '(auth)';

		if (
			// If the user is not signed in and the initial segment is not anything in the auth group.
			!token &&
			!inAuthGroup
		) {
			// Redirect to the sign-in page.
			router.replace('/signin');
		} else if (token && inAuthGroup) {
			// Redirect away from the sign-in page.
			router.replace('/home');
		}
	}, [token, segments]);
}

export function TokenProvider({skipLoading = false, initialToken = null, children}) {
	const [isTokenLoaded, setIsTokenLoaded] = useState(false);
	const [token, setToken] = useState(initialToken);

	useProtectedRoute(token);

	return (
		<TokenContext.Provider value={{token, setToken, isTokenLoaded, setIsTokenLoaded, skipLoading}}>
			{children}
		</TokenContext.Provider>
	);
}

export function useToken() {
	const {token, setToken: setTokenInternal, isTokenLoaded, setIsTokenLoaded, skipLoading} = useContext(TokenContext);

	useEffect(() => {
		if (!isTokenLoaded && !skipLoading) {
			getStringAsync(ACCESS_TOKEN_KEY).then((newToken) => {
				if (newToken) {
					setTokenInternal(newToken);
				}
				setIsTokenLoaded(true);
			});
		}
	}, [isTokenLoaded, skipLoading, setTokenInternal, setIsTokenLoaded]);

	const setToken = useCallback(
		async function (newToken) {
			await setStringAsync(ACCESS_TOKEN_KEY, newToken);
			setTokenInternal(newToken);
		},
		[setTokenInternal],
	);

	async function clearToken() {
		await deleteStringAsync(ACCESS_TOKEN_KEY);
		setTokenInternal(null);
	}

	const isLoggedIn = token !== null;

	return {token, isLoggedIn, isTokenLoaded, setToken, clearToken};
}
