// https://rail-backend.herokuapp.com/
import {Platform} from 'react-native';

const LOCAL_IP = '127.0.0.1';
// ifconfig | grep "inet "
// const LOCAL_IP = '10.0.1.52';

function getBaseUrl() {
	if (__DEV__) {
		if (Platform.OS === 'android') {
			return `http://${LOCAL_IP}:3000/api`;
		} else {
			return 'http://localhost:3000/api';
		}
	} else {
		// TODO: add custom domain before sending to App Store in case I switch off Heroku
		return 'https://rail-backend.herokuapp.com/api';
	}
}

const baseUrl = getBaseUrl();

export default baseUrl;
