import {Button, TextInput} from 'react-native-paper';
import {useToken} from '../../context/token';
import oauthLogin from '../../hook/oauthLogin';
import httpClient from '../../hook/httpClient';
import useLoginForm from '../../hook/useLoginForm';
import ScreenBackground from '../../components/common/webscreen/ScreenBackground';
import CenterColumn from '../../components/common/webscreen/CenterColumn';
import sharedStyles from '../../components/common/webscreen/sharedStyles';

export default function SignIn() {
	const {setToken} = useToken();
	const onLogIn = ({username, password}) =>
		oauthLogin({
			httpClient: httpClient(),
			username,
			password,
		}).then(setToken);
	const {username, password, handleChange, handleLogIn} = useLoginForm(onLogIn);

	return (
		<ScreenBackground style={sharedStyles.bodyPadding}>
			<CenterColumn>
				<TextInput
					label='Email'
					accessibilityLabel='Email'
					testID='email-field'
					value={username}
					onChangeText={handleChange('username')}
					keyboardType='email-address'
					autoCapitalize='none'
					autoCorrect={false}
				/>
				<TextInput
					label='Password'
					accessibilityLabel='Password'
					testID='password-field'
					value={password}
					onChangeText={handleChange('password')}
					secureTextEntry
				/>
				{/* <ErrorMessage>{error}</ErrorMessage> */}
				<Button testID='sign-in-button' mode='contained' onPress={handleLogIn} accessibilityLabel='Sign in'>
					Sign in
				</Button>
			</CenterColumn>
		</ScreenBackground>
	);
}
