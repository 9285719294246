import {Slot} from 'expo-router';
import {TokenProvider} from '../../context/token';

export default function Root() {
	return (
		// Setup the auth context and render our layout inside of it.
		<TokenProvider>
			<Slot />
		</TokenProvider>
	);
}
