import {StyleSheet} from 'react-native';

const sharedStyles = StyleSheet.create({
	bodyPadding: {
		padding: 15,
	},
	button: {
		marginTop: 10,
	},
});

export default sharedStyles;
